import styled from "styled-components";

export const Container = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;

  background-color: ${(props) =>
    props.isOpen ? "rgba(var(--black), 1)" : "transparent"};

  ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: ${(props) => (props.isOpen ? "100vh" : "10vh")};
    padding: 130px 40px;
  }

  button {
    border: none;
    background: none;
    font-size: 1rem;
    display: inline-flex;
    align-items: flex-start;
  }

  a,
  button {
    font-size: 1.1rem;
  }

  a {
    text-decoration: 0;
  }

  #closeButton {
    display: block;
    float: right;
    margin: 10px;
    padding: 30px 40px;
  }

  .navItems {
    li {
      padding: 8px 14px 8px 0;
      margin: 12px 0;
      display: ${(props) => (props.isOpen ? "block" : "none")};
    }

    li:hover {
      filter: brightness(0.9);
    }

    #contact {
      padding: 10px 0;
    }

    #contact b {
      color: rgba(var(--pink), 0.9);
      border: 2px solid rgba(var(--pink), 0.9);
      padding: 8px 14px;
      border-radius: 5px;
    }
  }

  /* small tablet styles */
  @media screen and (min-width: 620px) {
    background-color: transparent;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 30px 40px;
    }

    .navItems {
      li {
        display: flex;
      }
    }

    #contact {
      padding: 8px 14px;
    }

    #closeButton {
      display: none;
    }
  }
`;
