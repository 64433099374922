import React from "react";
import { CiLocationArrow1 } from "react-icons/ci";

import { Container } from "./styles";

interface Experience {
  company: {
    name: string;
    URL?: string;
  };
  jobTitle: string;
  date: string;
  description: string;
  skills: string[];
}
interface ExperienceCardProps {
  experience: Experience;
}

const ExperienceCard: React.FC<ExperienceCardProps> = ({ experience }) => {
  const {
    company: { name, URL },
    jobTitle,
    date,
    description,
    skills,
  } = experience;
  return (
    <Container>
      <p className="date">{date}</p>
      <div className="job-description">
        <h4>
          {URL ? (
            <a href={URL} target="_blank" rel="noreferrer">
              <b>{jobTitle} - </b> {name} <CiLocationArrow1 size={22} />
            </a>
          ) : (
            <>
              <b>{jobTitle}-</b> {name}
            </>
          )}
        </h4>
        <p>{description}</p>
        <ul className="skills-list">
          {skills.map((skill, index) => (
            <li className="skill" key={index}>
              {skill}
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default ExperienceCard;
