import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 40px;

  a {
    text-decoration: none;

    b {
      margin-right: 5px;
    }
  }

  a:hover {
    filter: brightness(0.9);
  }

  .job-description {
    h4 {
      margin: 6px 0;

      b {
        color: rgba(var(--pink), 0.9);
      }

      a {
        display: flex;
        align-items: center;

        svg {
          margin: 0 4px;
        }
      }
    }

    p {
      margin-top: 16px;
      margin-bottom: 32px;
      font-weight: 300;
      font-size: 0.93rem;
    }
  }

  .skills-list {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .skill {
    margin: 5px;
    padding: 6px 12px;
    border-radius: 25px;
    border-bottom: 2px solid rgba(var(--pink), 0.9);
    font-size: 0.8rem;

    b {
      font-weight: 400;
    }
  }

  @media screen and (min-width: 860px) {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;

    .job-description {
      width: 70%;
      p {
        font-size: 0.85rem;
      }
    }

    .date {
      width: 20%;
      height: 100%;
      margin: 6px 26px;
      text-align: right;
    }
  }
`;
