import React from "react";

import { Container } from "./styles";
import me from "../../assets/images/me.jpg";

const About: React.FC = () => {
  return (
    <Container>
      <div className="parent">
        <img src={me} alt="Nauany" className="image1" />
        <div className="dotted-square" />
      </div>

      <div id="description">
        <h1>
          <span>About</span> me
        </h1>
        <p>
          Hi there, I'm Nauany, a fullstack web developer specialized in fixing
          your problems. I enjoy crafting seamless web solutions from concept to
          deployment. My focus is on creating user-friendly experiences that
          evolve based on thoughtful analysis of user metrics. When I'm not
          coding, you'll likely find me hanging out with my cat, finding
          inspiration for the next project. Let's work together to build
          something great!
        </p>
      </div>
    </Container>
  );
};

export default About;
