import styled from "styled-components";

export const Container = styled.ul`
  margin-bottom: 60px;
  display: flex;
  justify-content: right;

  li {
    margin: 18px 15px;
    flex-grow: 0;
  }
`;
