import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;

    margin: 30px;
    border: 2px solid rgba(var(--pink), 0.9);

    object-position: 0px -32px;
    object-fit: cover;
  }

  h1 b {
    font-weight: 900;
    letter-spacing: 5px;
  }

  span {
    color: rgba(var(--pink), 0.9);
  }

  p {
    padding-left: 0px;
  }
`;
