import React from "react";

import { Container } from "./styles";
import Hero from "../Hero";

const Banner: React.FC = () => {
  return (
    <Container>
      <Hero />
    </Container>
  );
};

export default Banner;
