import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h3 {
    margin-top: 125px;
    margin-bottom: 10px;

    border-bottom: 1px solid rgba(var(--pink), 0.9);
    border-width: 10%;
  }

  h3 span {
    color: rgba(var(--pink), 0.9);
  }
`;
