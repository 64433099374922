import styled from "styled-components";
import background from "../../assets/images/background.jpg";

export const Container = styled.div`
  padding-top: 110px;
  width: 100%;
  background: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right 50% top 100%;
  box-shadow: inset 0 0 0 2000px rgba(var(--image-filter), 0.5);

  min-height: 630px;

  /* mobile styles */

  /* small tablet styles */
  @media screen and (min-width: 620px) {
  }

  /* large tablet & laptop styles */
  @media screen and (min-width: 960px) {
  }

  /* desktop styles */
  @media screen and (min-width: 1200px) {
  }
`;
