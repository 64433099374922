import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    --black: 12, 12, 12;
    --pink: 255, 197, 249;
    --white: 255, 250, 250;
    --image-filter: 3, 3, 3;
  }

  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
    outline:0;
    letter-spacing: 1.6px;
  }

  body{ 
    -webkit-font-smoothing: antialiased;
    background: rgba(var(--black), 0.9);
  }

  h1 {
    font-size: 40px;
  }
   
  button, a{
    cursor: pointer;
  }

  hr{
    border: 1px solid rgba(var(--pink),0.9);
  }

  li { 
    list-style: none;
  }
`;
