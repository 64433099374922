import styled from "styled-components";

export const Container = styled.div`
  margin: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .parent {
    position: relative;
    top: 0;
    left: 0;
  }

  .picture {
    position: relative;
    top: 0;
    left: 0;
  }

  img {
    border: 1px solid rgba(var(--pink), 0.9);
    max-width: 520px;
  }

  .dotted-square {
    height: 32%;
    width: 32%;
    position: absolute;
    z-index: -13;
    top: -40px;
    left: -40px;
    --d: 3px;

    background: radial-gradient(
        circle at var(--d) var(--d),
        rgba(var(--pink), 0.9) calc(var(--d) - 1px),
        #0000 var(--d)
      )
      0 0 / 14px 14px;
  }

  #description {
    width: 95%;
    text-align: left;

    h1 {
      margin: 60px 0 20px;
      span {
        color: rgba(var(--pink), 0.9);
        border-bottom: 1px solid rgba(var(--pink), 0.9);
      }
    }

    p {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 960px) {
    width: 80%;
    margin: 80px auto;
    justify-content: center;
    flex-direction: row;

    .dotted-square {
      height: 33%;
      width: 33%;
      position: absolute;
      z-index: -13;
      top: -40px;
      left: -40px;
      --d: 3px;
    }

    img {
      width: 100%;
      max-width: 360px;
    }

    #description {
      margin-left: 50px;
      width: 40%;

      h1 {
        margin: 0px 0 20px;
        padding-top: 0;
      }
    }
  }
`;
