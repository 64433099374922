import React, { ReactNode, useState } from "react";
import { Container } from "./styles";
import Navbar from "../Navbar";

interface LayoutProps {
    pageContent: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ pageContent }) => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    return (
        <Container isNavbarOpen={isNavbarOpen}>
            <Navbar isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} />
            {pageContent}
        </Container>
    );
};

export default Layout;
