import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 60px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    span {
      margin: 0 8px;
      color: rgba(var(--pink), 0.9);
      border-bottom: 1px solid rgba(var(--pink), 0.9);
    }
  }

  @media screen and (min-width: 860px) {
    font-size: 0.95rem;

    hr {
      display: none;
    }

    #experiences-list {
      width: 65%;
      max-width: 1120px;
    }
  }
`;
