import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  body {
    font-size: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  a,
  button,
  b,
  li {
    color: rgba(var(--white), 0.9);
    font-family: "Poppins";
  }

  img {
    max-width: 100%;
  }
`;

/* mobile styles */

/* small tablet styles */
/* @media screen and (min-width: 620px){
  
} */

/* large tablet & laptop styles */
/* @media screen and (min-width: 960px){

} */

/* desktop styles */
/* @media screen and (min-width: 1200px){

} */
