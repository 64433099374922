import React from "react";
import me from "../../assets/images/me.jpg";

import { Container } from "./styles";

const Hero: React.FC = () => {
  return (
    <Container>
      <img src={me} alt="Nauany" />
      <h1>
        <b>Nauany Costa</b>
      </h1>
      <p>
        I’m a <span>tech lover, coffee addict developer</span> <br /> from Minas
        Gerais, Brazil
      </p>
    </Container>
  );
};

export default Hero;
