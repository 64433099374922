import React from 'react';
import { IoIosMenu } from "react-icons/io";



import { Container } from "./styles";


interface NavbarProps {
  isNavbarOpen: boolean;
  setIsNavbarOpen: (currentState: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ isNavbarOpen, setIsNavbarOpen }) => {

  return (
    <Container isOpen={isNavbarOpen}  >
      <button id="closeButton" onClick={() => setIsNavbarOpen(!isNavbarOpen)}><IoIosMenu size="35px" /></button>
      <ul className="navItems">
        <li>
          <button>
            <b>English</b>
          </button>
        </li>
        <li>
          <a href="#about">
            <b>About</b>
          </a>
        </li>
        <li>
          <a href="#projects">
            <b>Projects</b>
          </a>
        </li>
        <li id="contact">
          <a href="#contact" >
            <b>Contact</b>
          </a>
        </li></ul>
    </Container >
  );
};



export default Navbar;
