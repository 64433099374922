import React from "react";
import { BsGoogle } from "react-icons/bs";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { IconContext } from "react-icons";

import { Container } from "./styles";

const Socials: React.FC = () => {
  return (
    <Container>
      <IconContext.Provider
        value={{ color: "rgba(249, 197, 255, 0.9)", size: "30px" }}
      >
        <li>
          <a
            href="https://www.linkedin.com/in/nauany-costa/"
            target="_blank"
            rel="noreferrer"
          >
            <BsGoogle />
          </a>
        </li>
        <li>
          <a href="https://github.com/NauanyC" target="_blank" rel="noreferrer">
            <AiFillGithub />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/nauany-costa/"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillLinkedin />
          </a>
        </li>
      </IconContext.Provider>
    </Container>
  );
};

export default Socials;
