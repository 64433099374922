import React from "react";
import { Container } from "./styles";
import About from "../components/About";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import { Rule } from "../components/Rule";
import Layout from "../components/Layout";
import Experiences from "../components/Experiences";

const generateHomePage = () => {
  return (
    <>
      <Banner />
      <Rule />
      {/* <Projects />
      <Rule /> */}
      <About />
      <Rule />
      <Experiences />
      <Footer />
    </>
  );
};

const Home: React.FC = () => {
  return (
    <Container>
      <Layout pageContent={generateHomePage()} />
    </Container>
  );
};

export default Home;
