import React from "react";
import { FaCat } from "react-icons/fa";

import { Container } from "./styles";
import ExperienceCard from "../ExperienceCard";

const experiences = [
  {
    company: {
      name: "Bitovi",
      URL: "https://www.bitovi.com/",
    },
    jobTitle: "Backend Developer ",
    date: "2022 - Present",
    description: `
    Delivered quality, well-written code for multiple projects. Elevated test coverage for projects, ensuring robust and reliable software delivery. Authored multiple blog posts for Bitovi. Worked in the development of an open-source project for automatically mocking GraphQL projects, helping test efforts and diverse other purposes. Integrated essential features from Temporal.io into projects, enhancing functionality.`,
    skills: [
      "Node",
      "Golang",
      "Typescript",
      "Temporal",
      "GraphQL",
      "PostgreSQL",
    ],
  },
  {
    company: {
      name: "ShareCare",
      URL: "https://www.sharecare.com/",
    },
    jobTitle: "Backend Developer ",
    date: "Jun 2021 - Feb 2022",
    description: `
    Engineered a high-performance software capable of efficiently handling tens of thousands of daily requests. Spearheaded the development of a pivotal rules engine service, implementing essential business features and integrating it across various company services. Took charge of refactoring three mission-critical services, enhancing their functionality and maintainability.`,
    skills: [
      "React",
      "NestJS",
      "Node",
      "GraphQL",
      "Python",
      "PostgreSQL",
      "MongoDB",
      "REST",
    ],
  },
  {
    company: {
      name: "Pling",
    },
    jobTitle: "Fullstack Developer ",
    date: "Oct 2020 - Mar 2021",
    description: `Addressed a critical legacy bug in the agenda app, unlocking further feature development. Efficiently implemented features across six distinct applications in a dynamic, fast-paced environment with frequent context switching. Played a pivotal role in advancing the functionalities of the rich software ecosystem.`,
    skills: ["React", "NextJS", "Node", "PostgreSQL", "MongoDB"],
  },
  {
    company: {
      name: "Crescer",
    },
    jobTitle: "System Analyst ",
    date: "May 2020 - Aug 2020",
    description: `
    Led the end-to-end development of a fullstack system for a private client, facilitating weekly meetings to plan features and gather feedback. Engineered a robust API utilizing governmental data to generate water metrics and developed a user-friendly Single Page Application (SPA) for data presentation, filtering, and download. Stepped in during staff shortage to produce critical healthcare reports using Ruby. Demonstrated adaptability, technical prowess, and effective communication throughout the project lifecycle.`,
    skills: ["React", "Golang", "MariaDB", "GraphQL"],
  },
];

const Experiences: React.FC = () => {
  return (
    <Container>
      <h1>
        Work <span>Path</span>
        <FaCat />
      </h1>
      <ul id="experiences-list">
        {experiences.map((experience, index) => (
          <>
            <ExperienceCard experience={experience} key={index} />
            <hr />
          </>
        ))}
      </ul>
    </Container>
  );
};

export default Experiences;
