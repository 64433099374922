import React from "react";
import { Container } from "./styles";
import Socials from "../Social";

const Footer: React.FC = () => {
  return (
    <Container>
      <h3>
        Let’s build cool <span>Stuff</span>
      </h3>
      <Socials />
    </Container>
  );
};

export default Footer;
